<template>
  <div>
    <b-card no-body class="mb-3 p-3">
      <div class="row">
        <input-text
          class="col-12 col-md"
          ref="Cnpj"
          label="CNPJ"
          v-mask="['###.###.###-##', '##.###.###/####-##']"
          v-model="form.Cnpj"
        />
        <input-text class="col-12 col-md" ref="Nome" label="Nome" v-model="form.Nome" />
        <input-text
          class="col-12 col-md"
          ref="NomeUsuario"
          label="Usuário"
          v-model="form.NomeUsuario"
        />
        <input-text
          class="col-12 col-md"
          ref="NomeEmpresa"
          label="Empresa"
          v-model="form.NomeEmpresa"
        />
        <input-text
          class="col-12 col-md"
          ref="Email"
          label="E-mail"
          v-model="form.email"
        />
        <input-select
          class="col-12 col-md"
          ref="Email"
          label="Status"
          v-model="form.ativo"
          :options="opcoes.status"
        />
        <div class="col-12 mt-3 d-flex align-items-center justify-content-end">
          <b-button variant="secondary" @click="limparFiltro" class="ml-2">
            {{ $t("GERAL.LIMPAR") }}
          </b-button>
          <b-button
            class="ml-2"
            variant="primary"
            @click="
              () => {
                listar();
              }
            "
          >
            {{ $t("GERAL.PESQUISAR") }}
          </b-button>

        </div>
      </div>
    </b-card>

    <valorem-tabela
      class="tabela-usuario"
      :responsiva="false"
      em-card
      sem-selecionar-todos
      :metodo-validacao-selecionar="metodoValidacaoSelecionar"
      v-model="tabela.dados"
      :colunas="tabela.colunas"
      :quantidade-itens="tabela.quantidadeItens"
      ref="tabela"
      backend-paginado
      @paginar="listar"
    >
      <template #antes-tabela>
        <div class="mb-2 d-flex justify-content-between">
          <div>
            <b-button
              id="btn-alterar-ativo"
              @click="ativarInativar(null, botaoAtivarInativar)"
              :disabled="!dadosSelecionados.length > 0"
              variant="outline-primary"
            >
              {{
                `${
                  !dadosSelecionados.length > 0
                    ? $t("USUARIOS.BOTAO_ATIVAR_INATIVAR")
                    : !botaoAtivarInativar
                    ? $t("USUARIOS.BOTAO_ATIVAR")
                    : $t("USUARIOS.BOTAO_INATIVAR")
                }`
              }}
            </b-button>
          </div>

          <b-button v-if="criar" variant="primary" @click="abrirCadastrar">
            {{ $t("GERAL.CADASTRAR") }}
          </b-button>
        </div>
      </template>
      <template #cell(acoes)="dados">
        <div class="flex">
          <b-dropdown variant="outline-default">
            <b-dropdown-item @click="abrirEditar(dados.item.id)">
              {{ $t("GERAL.EDITAR") }}
            </b-dropdown-item>
            <b-dropdown-item @click="excluir(dados.item)">
              {{ $t("GERAL.EXCLUIR") }}
            </b-dropdown-item>
            <b-dropdown-item @click="ativarInativar(dados.item.id, dados.item.ativo)">
              {{
                `${
                  !dados.item.ativo
                    ? $t("USUARIOS.BOTAO_ATIVAR")
                    : $t("USUARIOS.BOTAO_INATIVAR")
                }`
              }}
            </b-dropdown-item>
          </b-dropdown>
        </div>
      </template>
      <template #cell(ativo)="dados">
        <div class="text-center">
          <span :class="`text-${dados.value.variante}`">{{ dados.value.text }}</span>
        </div>
      </template>
    </valorem-tabela>
  </div>
</template>

<script>
import UsuarioService from "@/common/services/usuario/usuario.service";
import { START_LOADING, STOP_LOADING } from "@/store/Store";
import helpers from "@/common/utils/helpers";
import Confirmacao from "@/components/confirmacao/Confirmacao";
import { DEFINIR_BREADCRUMB } from "@/store/breadcrumbs.module";
import InputText from "@/components/inputs/InputText";
import InputSelect from "@/components/inputs/InputSelect";

export default {
  name: "Index",
  components: {
    InputText,
    InputSelect,
  },
  data() {
    return {
      criar: helpers.validarAcesso("Usuario").criar,
      form: {},
      opcoes: {
        status: [
          { text: "Todos", value: null },
          { text: "Ativos", value: true },
          { text: "Inativos", value: false },
        ],
      },
      tabela: {
        dados: [],
        colunas: [
          { key: "seletor", label: "" },
          {
            key: "nomeUsuario",
            label: this.$t("USUARIOS.USUARIO"),
            sortable: true,
          },
          {
            key: "nome",
            label: this.$t("USUARIOS.NOME"),
            sortable: true,
          },
          {
            key: "nomeEmpresa",
            label: this.$t("USUARIOS.NOME_EMPRESA"),
            sortable: true,
          },
          {
            key: "cnpj",
            label: this.$t("USUARIOS.CNPJ"),
            formatter: (value) => {
              if (value) return helpers.maskCnpj(value);
            },
            sortable: true,
          },
          {
            key: "email",
            label: this.$t("USUARIOS.EMAIL"),
            sortable: true,
          },
          {
            key: "celular",
            label: this.$t("USUARIOS.CELULAR"),
            sortable: true,
          },
          {
            key: "ativo",
            label: this.$t("USUARIOS.STATUS"),
            sortable: true,
            formatter: (v) => {
              if (!v) return { text: "Inativo", variante: "valorem-vermelho" };
              return { text: "Ativo", variante: "valorem-ciano" };
            },
          },
          {
            key: "acoes",
            label: this.$t("GERAL.ACOES"),
            tdClass:
              helpers.validarAcesso("Usuario").deletar == false &&
              helpers.validarAcesso("Usuario").editar == false
                ? "d-none"
                : "acoes",
            thClass:
              helpers.validarAcesso("Usuario").deletar == false &&
              helpers.validarAcesso("Usuario").editar == false
                ? "d-none"
                : "",
          },
        ],
        quantidadeItens: 0,
        paginaAtual: 0,
        porPagina: 0,
      },
      botaoAtivarInativar: false,
    };
  },
  mounted() {
    this.$store.dispatch(DEFINIR_BREADCRUMB, [{ titulo: this.$t("MENU.USUARIOS") }]);
    this.listar();
  },
  methods: {
    limparFiltro() {
      for (const key in this.form) {
        if (Object.hasOwnProperty.call(this.form, key)) {
          this.$set(this.form, key, undefined);
        }
      }
    },

    listar(paginaAtual = 0, porPagina = 10) {
      this.$store.dispatch(START_LOADING);

      this.form.TamanhoDaPagina = porPagina;
      this.form.NumeroDaPagina = paginaAtual;
      if (this.form.ativo == null) delete this.form.ativo;

      UsuarioService.listar(this.form)
        .then((res) => {
          res.data.data.itens.sort((a, b) => a.nomeUsuario.localeCompare(b.nomeUsuario));

          this.tabela.dados = res.data.data.itens;
          this.tabela.paginaAtual = paginaAtual;
          this.tabela.porPagina = porPagina;
          this.tabela.quantidadeItens = res.data.data.paginacao.totalDeElementos;
        })
        .finally(() => {
          this.$store.dispatch(STOP_LOADING);
        });
    },

    abrirCadastrar() {
      this.$router.push({ name: "usuarios-formulario" });
    },
    abrirEditar(id) {
      this.$router.push({ name: "usuarios-formulario", params: { id } });
    },
    excluir(item) {
      Confirmacao.SimNao(
        this,
        null,
        this.$t("USUARIOS.CONFIRMAR_EXCLUIR").formatUnicorn({
          nome: item.nome,
        })
      ).then(async (result) => {
        if (result.value) {
          this.$store.dispatch(START_LOADING);
          UsuarioService.excluir(item.id)
            .then(() => {
              this.$router.go();
            })
            .catch(() => {})
            .finally(() => {
              this.$store.dispatch(STOP_LOADING);
            });
        }
      });
    },
    metodoValidacaoSelecionar: function (item) {
      if (this.botaoAtivarInativar == null) return true;
      if (item.ativo != this.botaoAtivarInativar) return false;
      return true;
    },

    ativarInativar: function (id = null, ativarInativar) {
      this.confirmar(
        this.$t(`USUARIOS.TITULO_${!ativarInativar ? "ATIVAR" : "INATIVAR"}`),
        this.$t(`USUARIOS.CONFIRMACAO_${!ativarInativar ? "ATIVAR" : "INATIVAR"}`),
        this.$t(`USUARIOS.BOTAO_${!ativarInativar ? "ATIVAR" : "INATIVAR"}`),
        this.$t(`GERAL.CANCELAR`)
      ).then((confirmado) => {
        if (!confirmado) return;
        this.$store.dispatch(START_LOADING);
        UsuarioService.ativarInativar(
          { usuariosIds: id == null ? this.dadosSelecionados.map((el) => el.id) : [id] },
          !ativarInativar
        )
          .then(() => {
            this.toastSucesso(
              this.$t(`USUARIOS.${!ativarInativar ? "ATIVAR" : "INATIVAR"}_SUCESSO`)
            );
            this.listar(this.tabela.paginaAtual, this.tabela.porPagina);
          })
          .finally(() => {
            this.$store.dispatch(STOP_LOADING);
          });
      });
    },
  },
  computed: {
    dadosSelecionados: function () {
      return this.tabela.dados.filter((el) => {
        return el.selecionado;
      });
    },
  },
  watch: {
    dadosSelecionados: function () {
      if (this.dadosSelecionados.length == 1) {
        this.botaoAtivarInativar = this.dadosSelecionados[0].ativo;
      } else if (this.dadosSelecionados.length == 0) {
        this.botaoAtivarInativar = null;
      }
    },
  },
};
</script>
<style>
.tabela-usuario .tabela-valorem td {
  padding: 4px;
  font-size: 12px;
  vertical-align: top;
}
</style>
